globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"2481d371b53f26e3fccaeed4e7e3af9aad0a5599"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import commonConfig from './sentry.common.config';

Sentry.init(Object.assign({}, commonConfig, {
    integrations: [Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
    })/*, Sentry.feedbackIntegration({
        colorScheme: "system",
        isNameRequired: true,
        isEmailRequired: true,
    })*/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
}));
