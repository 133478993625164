import { type FC, memo, useEffect } from 'react';

import { clearMessages } from '@/store/notifications/actions';
import { useAppDispatch } from '@/store/hooks';
import { useMessages } from '@/store/notifications/hooks';

import { App, routerNavigate } from '@/components/structural';


export const Messages: FC = memo( () =>
{
    const { message } = App.useApp();

    const dispatchAction = useAppDispatch();
    const { messages } = useMessages();

    useEffect( () =>
    {
        if ( !messages || !messages.length ) return;

        messages.forEach( msg =>
        {
            message.open( {
                content: msg.content,
                type: msg.type,
                duration: 4.5,
                onClose: msg.onClose,
                onClick: () => msg.onClickNavigateTo && routerNavigate( msg.onClickNavigateTo )
            } ).then( () => null );
        } );

        dispatchAction( clearMessages() );
    }, [ messages, dispatchAction ] );

    return null;
} );

Messages.displayName = 'Messages';