import type { EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { baseQuery } from '@/store/_rtk-query/base-query';
import { compare, extractUuidFromUrl, getCookie } from '@/shared/tools';
import { processError } from '@/shared/processError';
import { providesIds, providesList } from '@/store/_rtk-query';

import { currentCompanyChanged } from '@/store/company/actions';

import { userApiKey } from './service.key';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORUser, IORUserMainAttributes } from '@/features/user/models';


export const userAdapter = createEntityAdapter<IORUser, string>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at, true ),
    selectId: ( e ) => e.uuid,
} );

export const mapUser = ( data: IORUser ) =>
{
    return ( {
        ...data,
        settings: {
            ...( data.settings || {} ),
            user_type: [ 'undefined', 'object' ].includes( typeof data.settings?.user_type ) ||
            [ 'null', 'undefined' ].includes( data.settings?.user_type ) ?
                  null :
                  data.settings?.user_type
        },
        default_account: extractUuidFromUrl( data.default_account || '' ),
    } );
};

export const userApi = createApi( {
    reducerPath: userApiKey,
    tagTypes: [
        'User',
        'Users',
    ],
    baseQuery: baseQuery( {
        baseUrl: '/user'
    } ),
    extractRehydrationInfo( action, { reducerPath } )
    {
        if ( action.type === HYDRATE )
        {
            return action.payload[ reducerPath ];
        }
    },
    refetchOnFocus: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_WINDOW_FOCUS ),
    refetchOnReconnect: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_RECONNECT ),
    keepUnusedDataFor: parseInt( process.env.NEXT_PUBLIC_UNUSED_CACHE_DURATION, 10 ),
    endpoints: ( builder ) => ( {
        getOneUser: builder.query<IORUser, string>( {
            query: ( uuid ) => ( {
                url: `/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'User' )
            ),
            transformResponse: mapUser,
            onQueryStarted: async ( _, api ) =>
            {
                try
                {
                    const { data } = await api.queryFulfilled;

                    let patchAttrs: Partial<IORUserMainAttributes> = {
                        settings: {
                            ...( data.settings || {} )
                        }
                    };
                    let initiate = false;

                    const currentAccountUuid = data.default_account || null;
                    if ( !data.settings.currentAccountUuid )
                    {
                        patchAttrs = {
                            ...patchAttrs,
                            settings: {
                                ...data.settings,
                                currentAccountUuid
                            }
                        };
                        initiate = true;
                    }

                    const email = getCookie( { cName: 'orNewSignupEmail' } );
                    if ( email === data.email )
                    {
                        const first_name = getCookie( { cName: 'orNewSignupFirstName' } );
                        if ( first_name !== null )
                        {
                            patchAttrs = {
                                ...patchAttrs,
                                first_name,
                            };
                        }

                        const last_name = getCookie( { cName: 'orNewSignupLastName' } );
                        if ( last_name !== null )
                        {
                            patchAttrs = {
                                ...patchAttrs,
                                last_name,
                            };
                        }
                    }

                    initiate && api.dispatch( userApi.endpoints.patchUser.initiate( { uuid: data.uuid, data: patchAttrs } ) );
                } catch ( error )
                {
                    processError( 'userApi Service', error );
                }
            }
        } ),
        getManyUsers: builder.query<EntityState<IORUser, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: '', method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'Users', 'User' )
            ),
            transformResponse: ( data: IORUser[], meta: IORResponseMetaData ) => ( {
                ...userAdapter.upsertMany( userAdapter.getInitialState(), data.map( mapUser ) ),
                ...{ meta }
            } ),
        } ),
        patchUser: builder.mutation<IORUser, { uuid: string, data: Partial<IORUserMainAttributes> }>( {
            queryFn: async ( { uuid, data = {} }, queryApi, _extraOptions, baseQuery ) =>
            {
                const userGetSelector = userApi.endpoints.getOneUser.select( uuid );
                // @ts-expect-error
                const userGetData = userGetSelector( queryApi.getState() ).data || { settings: {}, email: '' };

                if ( data.settings )
                {
                    data = {
                        ...data,
                        settings: {
                            ...userGetData.settings,
                            ...data.settings,
                        }
                    };
                }

                const user_type = getCookie( { cName: 'orNewSignupUserType' } ) as IORUserMainAttributes['settings']['user_type'];
                if ( user_type && !data.settings.user_type )
                {
                    data = {
                        ...data,
                        settings: {
                            ...data.settings,
                            user_type
                        }
                    };
                }

                const result = await baseQuery( {
                    url: `/${ uuid }`, method: 'patch', data
                } );

                if ( !result || result.error )
                {
                    return {
                        error: result?.error
                    };
                }

                return {
                    data: result.data as IORUser,
                    meta: result.meta
                };
            },
            onQueryStarted: async ( { data }, api ) =>
            {
                try
                {
                    await api.queryFulfilled;

                    if ( data?.settings?.currentCompanyUuid )
                    {
                        api.dispatch( currentCompanyChanged( data.settings.currentCompanyUuid ) );
                    }

                } catch ( error )
                {
                    processError( 'userApi Service', error );
                }
            },
            invalidatesTags: ( { uuid } ) => (
                  providesIds( [ uuid ], 'User' )
            )
        } ),
    } ),
} );

export const userApiReducer = userApi.reducer;
// export const userApiReducer = configureReduxPersist<ReturnType<typeof userApi.reducer>>( userApi.reducerPath, userApi.reducer );

export const {
    useGetOneUserQuery,
    useLazyGetOneUserQuery,
    useGetManyUsersQuery,
    useLazyGetManyUsersQuery,
    usePatchUserMutation,
    usePrefetch
} = userApi;
