import { useMemo } from 'react';
import useLocalStorageState from 'ahooks/lib/useLocalStorageState';

import { processError } from '@/shared/processError';

import type { IAppVersion } from '@/store/internal/models/version.interface';


const v = require( '@/project/package.json' ).version.split( '.' ).map( ( s: string ) => Number( s ) );

export const packageVersion: IAppVersion = {
    patch: v[ 2 ],
    minor: v[ 1 ],
    major: v[ 0 ]
};

export const useVersion = () =>
{
    const [ version, setVersion ] = useLocalStorageState<IAppVersion>( 'version', {
        defaultValue: () =>
        {
            setTimeout( () => setVersion( packageVersion ), 0 );
            return packageVersion;
        },
        onError: ( e ) => processError( 'useVersion Hook', e )
    } );

    const versionAsNumber = useMemo( () => Number( `${ version.major }${ version.minor }${ version.patch }` ), [ version ] );

    return useMemo( () => ( {
        version, setVersion, versionAsNumber
    } ), [ version, setVersion, versionAsNumber ] );
};