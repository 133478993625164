const SENTRY_DSN = process.env.NODE_ENV === 'production'?(process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN):'';
const packageJson = require('./package.json');

const config = {
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
    maxValueLength: 1_000_000,
    attachStacktrace: true,
    release: packageJson.name + '@' + packageJson.version
};

export default config;