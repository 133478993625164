import { type FC, memo, useMemo } from 'react';
import tw from 'tailwind-styled-components';

import { useCurrentCompany, useTagItems, useTags } from '@/store/company/hooks';
import { useTriggerCompanySyncMutation } from '@/store/company';

import { DataFormat, PageHeader, Tag, Typography } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';

import arrowClockwise from '@/icons/arrow-clockwise.svg';
import { COMPANY_COLOURS } from '@/shared/constants';


export interface IDashboardCompanyHeaderProps
{
    className?: string,
}

export const DashboardCompanyHeader: FC<IDashboardCompanyHeaderProps> = memo( ( props ) =>
{
    const [ trigger, { isLoading: syncIsLoading } ] = useTriggerCompanySyncMutation();

    const { orCompany, chLastUpdate, isFetching } = useCurrentCompany();
    const { tagItems } = useTagItems( orCompany?.uuid, undefined, !orCompany );
    const { tags } = useTags();

    const companyTags = useMemo( () => (
          tagItems?.map( tagItem => tags.find( tag => tag.uuid === tagItem.tag ) )
    ), [ tagItems, tags ] );

    const className = `p-0 pb-4 ${ props.className } ${ companyTags.length > 3 ? 'mt-4' : '' }`;

    return ( <>
        <PageHeader
              ghost
              className={ className }
              title={ <div
                    // className="flex items-center gap-4" // tags to the right
                    className="flex flex-col" // tags under
              >
                  <Typography.Title
                        ellipsis={ { tooltip: orCompany?.company_name } }
                        level={ 1 }
                        className="!font-normal !mb-0 !mt-0 grow"
                  >
                      { orCompany?.company_name }
                  </Typography.Title>
                  <div
                        className="flex items-center h-full shrink flex-wrap gap-1"
                  >
                      { companyTags?.map( ( tag, index ) => ( <>
                          <Tag
                                key={ tag.uuid }
                                // className="me-0 text-center w-[calc(100%*(1/4))]" // tags to the right
                                className="me-0 text-center" // tags under
                                color={ COMPANY_COLOURS[ index % COMPANY_COLOURS.length ] }
                          >
                              <Typography.Text
                                    ellipsis={ { tooltip: tag.text } }
                                    className="text-white"
                              >
                                  { tag.text }
                              </Typography.Text>
                          </Tag>
                      </> ) ) }
                  </div>
              </div> }
              extra={ <Typography.Text
                    className="flex flex-grow flex-col sm:flex-row place-items-center h-full relative mt-[1.5rem]"
              >
                  <Typography.Text
                        className="text-primary dark:text-primary-dark mr-2"
                  >
                      Last Companies House sync:
                  </Typography.Text>
                  <Typography.Text>
                      <DataFormat
                            raw={ chLastUpdate }
                            type="dateTime"
                            inline
                      />
                      <div
                            className="inline ml-2"
                            onClick={ () => trigger( orCompany?.uuid ) }
                      >
                          <LoadingIcon
                                { ...arrowClockwise }
                                alt="logo"
                                $animate={ isFetching || syncIsLoading }
                          />
                      </div>
                  </Typography.Text>
              </Typography.Text> }
        />
    </> );
} );

DashboardCompanyHeader.displayName = 'DashboardCompanyHeader';

const LoadingIcon = tw( SVGIcon )<{ $animate: boolean }>`
    ${ p => p.$animate ? 'animate-spin' : '' }

    !inline-block
    text-primary
        dark:text-primary-dark
    !w-5
    relative
    top-0.5
    cursor-pointer
`;