import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyFilingCompanyRegisteredAddress, IORCompanyFilingCompanyRegisteredAddressMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const filingCompanyRegisteredAddressAdapter = createEntityAdapter<IORCompanyFilingCompanyRegisteredAddress, string>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at, true ),
    selectId: ( e ) => e.uuid,
} );

const include = 'filing';

export const filingCompanyRegisteredAddressApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneFilingCompanyRegisteredAddress: builder.query<IORCompanyFilingCompanyRegisteredAddress, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-address/${ uuid }`, method: 'get', queryParams: {
                    include
                }
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'FilingCompanyRegisteredAddress' )
            ),
        } ),
        getManyFilingsCompanyRegisteredAddress: builder.query<EntityState<IORCompanyFilingCompanyRegisteredAddress, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-address/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'FilingsCompanyRegisteredAddress', 'FilingCompanyRegisteredAddress' )
            ),
            transformResponse: ( data: IORCompanyFilingCompanyRegisteredAddress[], meta: IORResponseMetaData ) => ( {
                ...filingCompanyRegisteredAddressAdapter.upsertMany( filingCompanyRegisteredAddressAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
        patchFilingCompanyRegisteredAddress: builder.mutation<IORCompanyFilingCompanyRegisteredAddress, { uuid: string, data: Partial<IORCompanyFilingCompanyRegisteredAddressMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-address/${ uuid }/`, method: 'patch', data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => (
                  providesIds( [ uuid ], 'FilingCompanyRegisteredAddress' )
            )
        } ),
        postFilingCompanyRegisteredAddress: builder.mutation<IORCompanyFilingCompanyRegisteredAddress, Partial<IORCompanyFilingCompanyRegisteredAddressMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-address/`, method: 'post', data
            } ),
            invalidatesTags: () => (
                  providesList( [], 'FilingsCompanyRegisteredAddress' )
            )
        } ),
        deleteFilingCompanyRegisteredAddress: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/companies-house/filings/registered-address/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => (
                  providesList( [ uuid ], 'FilingsCompanyRegisteredAddress', 'FilingCompanyRegisteredAddress' )
            )
        } ),
    } ),
} );

export const {
    useGetOneFilingCompanyRegisteredAddressQuery,
    useLazyGetOneFilingCompanyRegisteredAddressQuery,
    useGetManyFilingsCompanyRegisteredAddressQuery,
    useLazyGetManyFilingsCompanyRegisteredAddressQuery,
    usePatchFilingCompanyRegisteredAddressMutation,
    usePostFilingCompanyRegisteredAddressMutation,
    useDeleteFilingCompanyRegisteredAddressMutation,
    endpoints: filingCompanyRegisteredAddressApiEndpoints,
} = filingCompanyRegisteredAddressApi;