import { memo, type ReactElement, type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import useReactive from 'ahooks/lib/useReactive';
import useToggle from 'ahooks/lib/useToggle';
import tw from 'tailwind-styled-components';

import { createMessage } from '@/store/notifications/actions';
import { useAppDispatch } from '@/store/hooks';
import { useLastAction } from '@/store/_global/actions-store/actions.hooks';

import ConfigProvider from 'antd/lib/config-provider';
import { type DropzoneProps, useDropzone } from 'react-dropzone';
import { type ORColumnsType } from '@/components/tables/types';
import { type RowClassName } from 'rc-table/lib/interface';
import { type RowSelectionType, type SorterResult } from 'antd/lib/table/interface';

import { ActionBar } from '@/components/tables/ActionBar';
import { ButtonIcon, Table as TableOR, type TablePaginationConfig, type TableProps, UploadDropZone } from '@/components/structural';
import { ColumnsSelector } from '@/components/tables/ColumnsSelector';
import { EmptyTable } from './EmptyTable';
import { FilterBar } from '@/components/tables/FilterBar';
import { FilterButtons, type FilterRefType } from '@/components/tables/filtering-generators';

import { downloadExcelList } from '@/components/file-handling/tools/excel';
import type { FilterKeys, IFiltersState } from '@/components/tables/filtering-tools';
import { getDownloadFilenameForCompany } from '@/components/file-handling/tools';
import { getInitialSortByState, type ISorter, type ISorterState, onSortChange, prepareColumnItem } from './sorting-tools';
import { pageSizeOptions } from '@/components/tables/pageSizeOptions';
import { searchData } from '@/components/tables/search-tools';

import { getArray } from '@/shared/tools';

import type { ICellActions } from '@/components/tables/mapCellActions';
import type { IORCompany } from '@/features/company/shared/models';
import type { ORJSON } from '@/shared/models';

import downloadIcon from '@/icons/download.svg';
import uploadIcon from '@/icons/upload.svg';


export const TableRaw = TableOR;

export interface ITableWithActionFilterBarProps<TableData = unknown>
{
    size?: TableProps<TableData>['size'],
    data?: unknown,
    className?: string,
    getColumns: ( sortByColumn: ISorter, actions?: ICellActions<TableData> ) => ORColumnsType<TableData>,
    hideColumns?: string[],
    disableColumnsSelector?: boolean,
    cellActions?: ICellActions<TableData>,
    getParsedData?: ( rawData: unknown, filter: string ) => TableData[],
    parsedData?: TableData[],
    selectedRowKey?: string,
    disableSearch?: boolean,
    initialSearchValue?: string,
    filterKeys?: FilterKeys,
    filterTitles?: ORJSON<string>,
    filtersState?: IFiltersState,
    onFilterApply?: ( filters: IFiltersState ) => void,
    sortByOptions?: ReactElement[],
    sortByKeys?: string[],
    allowedSortKeys?: string[],
    sortBoxClassName?: string,
    onSortChange?: ( sorter: SorterResult<TableData> ) => void,
    dataIsLoading?: boolean,
    downloadConfig?: {
        filename: string,
        company?: Partial<IORCompany>,
        dateFields?: string[],
        omitFields?: string[],
        formatFields?: ORJSON<( data: unknown ) => string>
    }
    extraActionContentEnd?: ReactNode[] | ReactNode,
    extraActionContentMiddle?: ReactNode[] | ReactNode,
    extraActionContentStart?: ReactNode[] | ReactNode,
    contentImmediatelyAboveTable?: ReactNode[] | ReactNode,
    onRowSelected?: ( selectedData: TableData[] ) => void,
    selectedRowKeys?: string[],
    maxSelectedRows?: number,
    rowSelectionType?: RowSelectionType,
    disablePagination?: boolean,
    paginationTotalRecords?: number,
    onPaginationChange?: TablePaginationConfig['onChange'],
    onFilesDrop?: DropzoneProps['onDrop'],
    allowUploads?: boolean,
    disableHorizontalScroll?: string | number | boolean,
    scroll?: TableProps<TableData>['scroll']
}

export type ORTableRowVariant = 'primary' | 'danger' | 'info' | 'warning' | 'success';

type TableDataType = { key: string, rowVariant?: ORTableRowVariant };

const TableInternal = <TableData extends TableDataType>
( props: ITableWithActionFilterBarProps<TableData> ) =>
{
    if ( process.env.NODE_ENV !== 'production' && !props.getParsedData && !props.parsedData )
    {
        throw new Error( 'Table: either "getParsedData" or "parsedData" must be present.' );
    }

    const dispatch = useAppDispatch();
    const sortByOptions = useMemo( () => getArray<typeof props.sortByOptions[number]>( props.sortByOptions ), [ props.sortByOptions ] );
    const sortByColumn = useReactive<ISorterState>( getInitialSortByState( sortByOptions[ 0 ]?.props.value || undefined ) );

    const [ hiddenColumns, setHiddenColumns ] = useState( getArray<typeof props.hideColumns[number]>( props.hideColumns ) );
    const activeColumns = useMemo( () =>
    {
        const cols = props.getColumns( sortByColumn.internal, props.cellActions );
        const colsWithoutHidden = hiddenColumns.length &&
              cols.filter( ( col ) =>
                    !hiddenColumns.includes( col.key.toString() )
              ) || cols;

        if ( props.disableColumnsSelector === undefined || !props.disableColumnsSelector )
        {
            colsWithoutHidden.unshift( prepareColumnItem<TableData>( {
                title: <div className="text-center"><ColumnsSelector<TableData>
                      columns={ cols }
                      onColumnSelectorAction={ ( columnKeys ) =>
                      {
                          setHiddenColumns( () => [
                              ...getArray<typeof props.hideColumns[number]>( props.hideColumns ),
                              ...columnKeys
                          ] );
                      } }
                /></div>,
                className: 'w-8',
                key: '---selector---' as keyof TableData,
                render: () => <div className="updated-dot-container"/>,
            } ) );
        }

        return colsWithoutHidden;
    }, [ sortByColumn.internal, sortByColumn.internal.key, sortByColumn.internal.direction, hiddenColumns ] );

    const filterBarRef = useRef<ORJSON<FilterRefType<unknown>>>( {} );
    const [ searchStr, setSearchStr ] = useState<string>();
    const [ filtersActive, setFiltersActive ] = useState( props.filtersState && Object.keys( props.filtersState ).length > 0 );
    const [ showFilterBar, { toggle, set: setToggle } ] = useToggle( props.filtersState && Object.keys( props.filtersState ).length > 0 );
    const filtersState = useReactive<IFiltersState>( { ...props.filtersState } || {} );

    useEffect( () =>
    {
        if ( !props.filtersState || !Object.keys( props.filtersState ).length || !props.onFilterApply ) return;

        Object.keys( props.filtersState ).forEach( key =>
        {
            filtersState[ key ] = { ...props.filtersState[ key ] };
        } );

        setFiltersActive( true );
        setToggle( true );

        props.onFilterApply( props.filtersState );
    }, [ props.filtersState ] );

    const data = useMemo( () => (
          props.getParsedData ?
                props.getParsedData( props.data, searchStr )
                : searchData( props.parsedData, searchStr )
    ), [ searchStr, props.data, props.getParsedData, props.parsedData ] );

    const downloadData = useCallback( () =>
    {
        if ( props.downloadConfig )
        {
            const filename = getDownloadFilenameForCompany( props.downloadConfig.filename, props.downloadConfig.company );
            downloadExcelList(
                  data,
                  filename,
                  props.downloadConfig.dateFields,
                  props.downloadConfig.omitFields,
                  props.downloadConfig.formatFields,
            );
            dispatch( createMessage( {
                content: `"${ filename }.xlsx" has been successfully downloaded. Please check your local Downloads folder.`,
                type: 'success'
            } ) );
        }
    }, [ data, props.downloadConfig ] );

    const onTableChange = useCallback( ( _, __, sorter: SorterResult<TableData>/*, ___*/ ) =>
    {
        if ( props.allowedSortKeys && !props.allowedSortKeys.includes( sorter.columnKey as string ) )
        {
            return;
        }

        onSortChange( `${ sorter.columnKey }+${ sorter.order || '' }`, sortByColumn, props.sortByKeys );

        if ( props.onSortChange )
        {
            if ( sorter.order || !sortByOptions.length )
            {
                props.onSortChange( sorter );
            } else
            {
                props.onSortChange( {
                    ...sorter,
                    columnKey: sortByOptions[ 0 ].props.column,
                    order: sortByOptions[ 0 ].props.direction,
                } );
            }
        }
    }, [ sortByColumn.internal, sortByColumn.internal.key, sortByColumn.internal.direction, props.sortByKeys, props.allowedSortKeys ] );

    const [ selectedRowKeys, setSelectedRowKeys ] = useState( getArray<typeof props.selectedRowKeys[number]>( props.selectedRowKeys ) );
    useEffect( () => setSelectedRowKeys( () => getArray<typeof props.selectedRowKeys[number]>( props.selectedRowKeys ) ), [ props.selectedRowKeys ] );
    const rowSelection = props.onRowSelected ? {
        selectedRowKeys,
        hideSelectAll: props.maxSelectedRows === 1,
        type: props.rowSelectionType || ( props.maxSelectedRows === 1 ? 'radio' : 'checkbox' ) as RowSelectionType,
        onChange: ( selectedKeys: TableData['key'][] ) =>
        {
            if ( typeof props.onRowSelected === 'function' )
            {
                const selectedRows = props.maxSelectedRows ? selectedKeys.slice( props.maxSelectedRows * -1 ) : selectedKeys;
                setSelectedRowKeys( () => [ ...selectedRows ] );
                const selectedData = data.filter( e => selectedRows.includes( e.key ) );
                props.onRowSelected( selectedData );
            }
        },
    } : undefined;

    const { lastAction } = useLastAction( ( la ) => (
          la.isApi &&
          la.endpoint.match( /(post|patch|put)/ ) &&
          la.type === QueryStatus.fulfilled &&
          'uuid' in la.payload
    ) );
    const [ recentlyUpdatedID, setRecentlyUpdatedID ] = useState<string>();
    const getRowClassName = useCallback<RowClassName<TableData>>( ( record ) =>
    {
        if ( record.key === recentlyUpdatedID || record.key === props.selectedRowKey )
        {
            return 'or-table-recently-updated';
        }

        if ( record.rowVariant )
        {
            return 'or-table-row-variant-' + record.rowVariant;
        }

        return '';
    }, [ recentlyUpdatedID, props.selectedRowKey ] );

    useEffect( () =>
    {
        if ( !lastAction ) return;
        setRecentlyUpdatedID( () => lastAction.payload[ 'uuid' ] as string );
    }, [ lastAction ] );

    useEffect( () =>
    {
        if ( !recentlyUpdatedID && !props.selectedRowKey ) return;

        const timeout = setTimeout( () =>
        {
            const elementsByClassName = document.getElementsByClassName( 'or-table-recently-updated' );

            for ( let i = 0; i < elementsByClassName.length; i++ )
            {
                const el = elementsByClassName.item( i );

                try
                {
                    el.scrollIntoView( { behavior: 'smooth', block: 'center', inline: 'nearest' } );
                } catch ( e )
                {
                    const alignToTop = true;
                    el.scrollIntoView( alignToTop );
                }
            }
        }, 100 );

        return () => clearTimeout( timeout );
    }, [ recentlyUpdatedID, props.selectedRowKey ] );

    const totalRecords = searchStr ? data.length : ( props.paginationTotalRecords || data.length );
    const table = useMemo( () => <TableRaw<TableData>
          className={ props.className }
          // @ts-expect-error
          $tableEmpty={ data.length === 0 }
          $columnSelectorActive={ props.disableColumnsSelector !== true }
          dataSource={ data }
          size={ props.size }
          columns={ activeColumns }
          rowClassName={ getRowClassName }
          loading={ props.dataIsLoading || false }
          showSorterTooltip={ false }
          onChange={ onTableChange }
          scroll={ Object.assign( props.disableHorizontalScroll ? {
              scrollToFirstRowOnChange: true,
          } : {
              scrollToFirstRowOnChange: true,
              x: true,
          }, props.scroll ) }
          tableLayout="auto"
          pagination={ props.disablePagination ? false : {
              hideOnSinglePage: false,
              pageSizeOptions,
              showSizeChanger: true,
              showTotal: ( total ) => `${ total } Records`,
              showQuickJumper: true,
              size: 'small',
              // TODO: add this back in once server has a way of handling calculated fields
              // onChange: props.onPaginationChange,
              total: totalRecords,
          } }
          rowSelection={ rowSelection }
    />, [
        activeColumns,
        data,
        getRowClassName,
        onTableChange,
        props.dataIsLoading,
        props.disableHorizontalScroll,
        props.disablePagination,
        /*props.onPaginationChange,*/ // TODO: add this back in once server has a way of handling calculated fields
        props.paginationTotalRecords,
        props.scroll,
        props.size,
        rowSelection,
        totalRecords
    ] );

    const { getRootProps, getInputProps, isDragActive, open: openDropzone } = useDropzone( {
        noClick: true,
        noKeyboard: true,
        onDrop: props.onFilesDrop
    } );

    const allowUpload = data.length === 0 && !filtersActive || props.allowUploads;

    return ( <>
        { !!( props.sortByKeys && props.sortByOptions ||
              props.filterKeys && props.filterTitles ||
              props.initialSearchValue ||
              props.downloadConfig ||
              props.onFilesDrop ||
              props.extraActionContentEnd ||
              props.extraActionContentMiddle ||
              props.extraActionContentStart ) && <>
            <ActionBar
                  sortByBoxClassName={ `w-full xs:w-48 ${ props.sortBoxClassName || '' }` }
                  isLoading={ props.dataIsLoading || false }
                  setSearchStr={ props.disableSearch === true ? undefined : setSearchStr }
                  initialSearchValue={ props.initialSearchValue }
                  sortByColumn={ sortByColumn }
                  sortByKeys={ props.sortByKeys }
                  sortByOptions={ sortByOptions }
                  onSortChange={ ( columnKey, sorter ) => props.onSortChange && props.onSortChange( {
                      columnKey,
                      order: sorter.visual.direction
                  } ) }
                  extraLeftContent={ props.extraActionContentStart }
                  placeExtraLeftContent="start"
            >
                { props.extraActionContentMiddle }
                { props.filterKeys && props.filterTitles &&
                      <FilterButtons
                            onClick={ toggle }
                            showFilterBar={ showFilterBar }
                            filtersActive={ filtersActive }
                            className="mr-4"
                      /> }
                { props.downloadConfig &&
                      <ButtonIcon
                            onClick={ downloadData }
                            { ...downloadIcon }
                            iconClasses="!w-6 !h-6"
                            className="mr-4"
                      /> }
                { props.onFilesDrop &&
                      <ButtonIcon
                            onClick={ openDropzone }
                            src={ uploadIcon.src }
                            iconClasses="!w-6 !h-6"
                            className="mr-4"
                      /> }
                { props.extraActionContentEnd }
            </ActionBar>
            { props.filterKeys && props.filterTitles &&
                  <FilterBar
                        showFilterBar={ showFilterBar }
                        filterKeys={ props.filterKeys }
                        filterTitles={ props.filterTitles }
                        filterBarRef={ filterBarRef }
                        filtersState={ filtersState }
                        filtersActive={ filtersActive }
                        setFiltersActive={ setFiltersActive }
                        toggleFilterBarFn={ toggle }
                        onFilterApply={ () => props.onFilterApply && props.onFilterApply( filtersState ) }
                        onFilterClear={ () => props.onFilterApply && props.onFilterApply( null ) }
                  /> }
        </> }
        { props.contentImmediatelyAboveTable }
        { props.onFilesDrop && allowUpload ? <>
                  <DropzoneOver
                        $isDragActive={ isDragActive }
                        { ...getRootProps( { className: 'relative' } ) }
                  >
                      <input { ...getInputProps() } />
                      { isDragActive && <UploadDropZone/> }
                      <ConfigProvider renderEmpty={ () => <EmptyTable showUploadMessage={ true }/> }>
                          { table }
                      </ConfigProvider>
                  </DropzoneOver>
              </>
              :
              <ConfigProvider renderEmpty={ () => <EmptyTable showUploadMessage={ false }/> }>
                  { table }
              </ConfigProvider>
        }
    </> );
};
TableInternal.displayName = 'Table';
export const Table = memo( TableInternal ) as typeof TableInternal;

const DropzoneOver = tw.div<{ $isDragActive: boolean }>`
    ${ p => p.$isDragActive ? `
        rounded-xl
    ` : '' }
`;

