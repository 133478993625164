import { memo, useCallback, useEffect, useState } from 'react';
import { useResponsive } from 'ahooks/lib/useResponsive';

import { formatWordsWithSeparator, normaliseString } from '@/shared/tools';

import Dropdown from 'antd/lib/dropdown';

import { SVGIcon } from '@/components/structural/images';

import type { Breakpoint } from '@/components/tables/getORBreakpointsHigherThan';
import type { ORColumnsType } from '@/components/tables/types';

import dotsIcon from '@/icons/three-dots-vertical.svg';


export interface IColumnResponsive
{
    key: string,
    minBreakPoint: Breakpoint
}

export interface IColumnsSelectorProps<T = unknown>
{
    columns: ORColumnsType<T>,
    onColumnSelectorAction: ( columnKeys: string[] ) => void,
    className?: string,
    responsiveColumns?: IColumnResponsive[]
}

const ColumnsSelectorInternal = <T, >( props: IColumnsSelectorProps<T> ) =>
{
    const breakpoints = useResponsive();

    const [ initialColumns ] = useState( props.columns.map( c => ( {
        key: c.key as string,
        title: c.title,
        entity: c,
        minBreakPoint: c.$minBreakPoint
    } ) ) );
    const [ hiddenColumns, setHiddenColumns ] = useState<string[]>( [] );

    const columnsSelectorAction = useCallback( ( columnKeys: string[] ) =>
    {
        props.onColumnSelectorAction && setHiddenColumns( columnKeys );
    }, [ props.onColumnSelectorAction ] );

    const [ menuVisible, setMenuVisible ] = useState( false );

    const getColumnsSelectorOptions = useCallback( () => ( {
        items: initialColumns.map( c =>
        {
            if ( c.key === '---selector---' )
            {
                return;
            }

            let title: string;

            if ( typeof c.title === 'string' )
            {
                title = c.title;
            } else
            {
                title = formatWordsWithSeparator( normaliseString( c.key ), '_' );
            }

            const action = ( key: string ) =>
            {
                if ( hiddenColumns.includes( key ) )
                {
                    columnsSelectorAction( hiddenColumns.filter( col => col !== key ) );
                } else
                {
                    columnsSelectorAction( [ ...hiddenColumns, key ] );
                }
            };

            const getClassName = ( key: string ) =>
                  !hiddenColumns.includes( key ) ? '!text-primary dark:!text-primary-dark' : '';

            return ( {
                label: title,
                key: c.key,
                onClick: () => action( c.key ),
                className: getClassName( c.key )
            } );
        } )
    } ), [ initialColumns, hiddenColumns ] );

    useEffect( () =>
    {
        props.onColumnSelectorAction && props.onColumnSelectorAction( hiddenColumns );
    }, [ hiddenColumns ] );

    useEffect( () =>
    {
        setHiddenColumns(
              initialColumns.filter( c => c.minBreakPoint && !breakpoints[ c.minBreakPoint ] ).map( c => c.key )
        );
    }, [ breakpoints ] );

    return ( <>
        <Dropdown
              menu={ getColumnsSelectorOptions() }
              trigger={ [ 'click' ] }
              onOpenChange={ setMenuVisible }
              open={ menuVisible }
              className="align-middle"
        >
            <span><SVGIcon src={ dotsIcon.src } className="!w-6 !h-6" link/></span>
        </Dropdown>
    </> );
};
ColumnsSelectorInternal.displayName = 'ColumnsSelector';
export const ColumnsSelector = memo( ColumnsSelectorInternal ) as typeof ColumnsSelectorInternal;