import { type FC, memo, useEffect, useMemo, useRef } from 'react';

import { Input } from '@/components/structural/form';

import { processError } from '@/shared/processError';


export interface IAddressLookupProps
{
    className?: string,
    onSelect: ( address: FetchifyAddressLookupResult ) => void,
    defaultCountry?: string,
}

export const AddressLookup: FC<IAddressLookupProps> = memo( ( props ) =>
{
    const fetchifyRef = useRef( null );
    const domElId = useMemo( () => 'address_search', [] );

    useEffect( () =>
    {
        try
        {
            fetchifyRef.current = new clickToAddress( {
                accessToken: process.env.NEXT_PUBLIC_FETCHIFY_KEY,
                dom: {
                    search: domElId,
                },
                domMode: 'id',
                defaultCountry: 'gbr',
                disableAutoSearch: false,
                transliterate: true,
                onResultSelected: ( _c2a, _elements, address ) =>
                {
                    props.onSelect?.( address );
                    setTimeout( () =>
                    {
                        ( document.getElementById( domElId ) as HTMLInputElement ).value = '';
                    }, 100 );
                },
            } );
        } catch ( e )
        {
            processError( 'AddressLookup Component', e );
        }

        const timeout = setTimeout( () =>
        {
            try
            {
                fetchifyRef.current?.selectCountry( 'GB' );
            } catch ( e )
            {
                processError( 'AddressLookup Component', e );
            }
        }, 1500 );

        return () =>
        {
            clearTimeout( timeout );
            document.getElementById( 'cc_c2a' )?.remove();
        };
    }, [] );

    useEffect( () =>
    {
        if ( props.defaultCountry )
        {
            try
            {
                fetchifyRef.current?.selectCountry( props.defaultCountry );
            } catch ( e )
            {
                processError( 'AddressLookup Component', e );
            }
        }
    }, [ props.defaultCountry ] );

    return ( <>
        <div className={ `${ props.className }` }>
            <Input
                  id={ domElId }
            />
        </div>
    </> );
} );


AddressLookup.displayName = 'AddressLookup';