import { useMemo } from 'react';

import { useCurrentUser } from '@/store/user/hooks';
import { useGetOneCompanyQuery } from '@/store/company';


export const useCurrentCompany = ( skip = false ) =>
{
    const { settings } = useCurrentUser();
    const { data, isFetching, isLoading, error } = useGetOneCompanyQuery( settings?.currentCompanyUuid, {
        skip: skip || !settings?.currentCompanyUuid
    } );

    const orCompany = settings && data || null;

    const chProfile = orCompany?.ch_data.chProfile;
    const chOfficers = orCompany?.ch_data.chOfficers;
    const chPSCs = orCompany?.ch_data.chPersonsWithSignificantControl;
    const chFilings = orCompany?.ch_data.chFilingHistories;
    const chCharges = orCompany?.ch_data.chCharges;
    const chExemptions = orCompany?.ch_data.chExemptions;
    const chLastUpdate = orCompany?.ch_data.last_update;
    const chOrUuid = orCompany?.ch_data.uuid;
    const orCompanySettings = orCompany?.json_settings;

    return useMemo( () => ( {
        orCompany,
        orCompanySettings,
        uuid: orCompany?.uuid,
        chProfile,
        chOfficers,
        chPSCs,
        chFilings,
        chCharges,
        chExemptions,
        chLastUpdate,
        chOrUuid,
        isLoading,
        isFetching,
        error
    } ), [ orCompany, isLoading, isFetching, error ] );
};