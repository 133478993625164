import type { Reducer } from '@reduxjs/toolkit';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import type { PersistConfig } from 'redux-persist/lib/types';
import { persistReducer } from 'redux-persist';


const versionRaw = require( '@/project/package.json' ).version.split( '.' ).map( ( s: string ) => Number( s ) );
const version = Number( `${ versionRaw.major }${ versionRaw.minor }${ versionRaw.patch }` );

export const configureReduxPersist = <T>(
      sliceKey: string,
      sliceReducer: Reducer<T>,
      { blacklist = undefined, whitelist = undefined }: { blacklist?: string[], whitelist?: string[] } = {}
) =>
{
    const isApiReducer = !!sliceKey.toLowerCase().match( /(api)/g );

    const saveSubsetBlacklistFilter = createBlacklistFilter(
          sliceKey,
          isApiReducer ? [ 'config' ] : []
    );

    const config: PersistConfig<T> = {
        key: sliceKey,
        version,
        storage,
        stateReconciler: autoMergeLevel1,
        blacklist,
        whitelist,
        transforms: [
            saveSubsetBlacklistFilter,
        ],
    };

    return persistReducer( config, sliceReducer );
};

const createNoopStorage = () =>
{
    return {
        getItem()
        {
            return Promise.resolve( null );
        },
        setItem( _: unknown, value: unknown )
        {
            return Promise.resolve( value );
        },
        removeItem()
        {
            return Promise.resolve();
        },
    };
};

const storage = typeof window !== 'undefined' ? createWebStorage( 'local' ) : createNoopStorage();