import { type ReactNode, Suspense } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import tw from 'tailwind-styled-components';

import { storeUtils } from '@/store';

import ModalAntD, { type ModalFuncProps } from 'antd/lib/modal';

import { App, Button, ConfigProvider, LoaderFullscreen, ModalTitle, type ORButtonType, Typography } from '@/components/structural';
import { SentryErrorBoundary } from '@/components/SentryErrorBoundary';
import { SVGIcon } from '@/components/structural/images';

import closeIcon from '@/icons/x.svg';
import expandIcon from '@/icons/arrows-angle-expand.svg';
import contractIcon from '@/icons/arrows-angle-contract.svg';

import { themeConfig } from '@/styles/themeConfig';


export const Modal = ModalAntD;

export type ModalLiveReference = { destroy: () => void, update: ( configUpdate: Partial<ModalFuncProps> ) => void }

export interface IOpenModalProps
{
    type: ModalFuncProps['type'],
    title?: ReactNode,
    icon?: string,
    children: ReactNode[] | ReactNode,
    className?: ModalFuncProps['className'],
    containerClassName?: string,
    closable?: ModalFuncProps['closable'],
    expandable?: boolean,
    centered?: ModalFuncProps['centered'],
    centerTitle?: boolean,
    maskClosable?: ModalFuncProps['maskClosable'],
    okButtonProps?: ModalFuncProps['okButtonProps'],
    okText?: ModalFuncProps['okText'],
    cancelButtonProps?: ModalFuncProps['cancelButtonProps'],
    cancelText?: ModalFuncProps['cancelText'],
    onOk?: ModalFuncProps['onOk'],
    onCancel?: ModalFuncProps['onCancel'],
    containsTabs?: boolean,
}

export const openModal = ( props: IOpenModalProps ): ModalLiveReference =>
{
    const {
        className = '',
        containerClassName = '-mb-16',
        closable = true,
        centered = true,
        centerTitle = false,
        maskClosable = true,
        okButtonProps = { style: { display: 'none' } },
        okText = '',
        cancelButtonProps = { style: { display: 'none' } },
        cancelText = '',
        containsTabs = false,
    } = props;

    const classNameMerged = `${ className.includes( 'w-[' ) || className.includes( 'w-' ) ? '' : '!w-[400px] md:!w-[500px] lg:!w-[650px]' } h-[95vh] scrollbar-hidden ${ className }`;

    let originalClasses = '';

    const expandModal = () =>
    {
        const modal = document.querySelector( '.or-modal-container' );
        if ( modal )
        {
            // Store the original classes
            originalClasses = Array.from( modal.classList ).filter( className => className.includes( 'w-' ) ).join( ' ' );

            // Remove the original classes
            modal.classList.remove( ...originalClasses.split( ' ' ) );

            // Add the new classes
            modal.classList.add( '!h-[100vh]', '!w-[100vw]' );
        }

        const contractButton = document.querySelector( '.or-modal-contract-button' );
        if ( contractButton )
        {
            contractButton.classList.remove( 'hidden' );
        }

        const expandButton = document.querySelector( '.or-modal-expand-button' );
        if ( expandButton )
        {
            expandButton.classList.add( 'hidden' );
        }
    };

    const contractModal = () =>
    {
        const modal = document.querySelector( '.or-modal-container' );
        if ( modal )
        {
            // Remove the new classes
            modal.classList.remove( '!h-[100vh]', '!w-[100vw]' );

            // Add back the original classes
            modal.classList.add( ...originalClasses.split( ' ' ) );
        }

        const contractButton = document.querySelector( '.or-modal-contract-button' );
        if ( contractButton )
        {
            contractButton.classList.add( 'hidden' );
        }

        const expandButton = document.querySelector( '.or-modal-expand-button' );
        if ( expandButton )
        {
            expandButton.classList.remove( 'hidden' );
        }
    };

    if ( props.expandable )
    {
        setTimeout( () =>
        {
            const modal = document.querySelector( '.or-modal-container' );
            if ( modal )
            {
                const closeButton = modal.querySelector( '.ant-modal-close' );
                if ( closeButton )
                {
                    const commonButtonClasses = 'absolute z-10 right-[2.5rem] top-[0.85rem] h-3 w-4 text-primary dark:text-primary-dark hover:cursor-pointer';

                    // Create the contract button
                    const contractButton = document.createElement( 'img' );
                    contractButton.src = contractIcon.src;
                    contractButton.className = 'or-modal-contract-button hidden ' + commonButtonClasses;
                    contractButton.addEventListener( 'click', contractModal );

                    const expandButton = document.createElement( 'img' );
                    expandButton.src = expandIcon.src;
                    expandButton.className = 'or-modal-expand-button ' + commonButtonClasses;
                    expandButton.addEventListener( 'click', expandModal );

                    closeButton.insertAdjacentElement( 'afterend', contractButton );
                    closeButton.insertAdjacentElement( 'afterend', expandButton );
                }
            }
        }, 100 );
    }

    return ModalAntD[ props.type ]( {
        title: props.title && <ModalTitle title={ props.title } icon={ props.icon } className={ centerTitle ? 'justify-center' : '' }/>,
        content: ( <>
            <Provider store={ storeUtils.store }>
                <PersistGate persistor={ storeUtils.store.__persistor }>
                    <ConfigProvider theme={ themeConfig }>
                        <App>
                            <SentryErrorBoundary>
                                <Suspense fallback={ <LoaderFullscreen/> }>
                                    <Container className={ props.title ?
                                          containerClassName :
                                          `-mt-10 ${ containerClassName }`
                                    }>
                                        { props.children }
                                    </Container>
                                </Suspense>
                            </SentryErrorBoundary>
                        </App>
                    </ConfigProvider>
                </PersistGate>
            </Provider>
        </> ),
        icon: null,
        className: `or-modal-container ${ containsTabs ? 'has-tabs ' : '' }${ classNameMerged } rounded-xl`,
        closable,
        closeIcon: <>
            <SVGIcon src={ closeIcon.src } className="h-6 w-6 text-primary dark:text-primary-dark"/>
        </>,
        wrapClassName: 'scrollbar-light dark:scrollbar-dark h-[100vh] py-[1vh]',
        centered,
        maskClosable,
        okButtonProps,
        okText,
        cancelButtonProps,
        cancelText,
        onOk: props.onOk,
        onCancel: props.onCancel,
    } );
};

export interface IOpenConfirmModalProps
{
    title: IOpenModalProps['title'],
    body: IOpenModalProps['children'],
    onOk: IOpenModalProps['onOk'],
    okText?: IOpenModalProps['okText'],
    type?: IOpenModalProps['type'],
    containerClassName?: IOpenModalProps['containerClassName'],
    onCancel?: IOpenModalProps['onCancel'],
    cancelText?: IOpenModalProps['cancelText'],
    okButtonProps?: IOpenModalProps['okButtonProps'],
    cancelButtonProps?: IOpenModalProps['cancelButtonProps'],
    maskClosable?: IOpenModalProps['maskClosable'],
    closable?: IOpenModalProps['closable'],
}

export const openConfirmModal = ( props: IOpenConfirmModalProps ) =>
{
    return openModal( {
        type: props.type || 'confirm',
        title: props.title,
        centerTitle: true,
        children: props.body,
        containerClassName: props.containerClassName || '',
        okText: props.okText || 'Yes',
        okButtonProps: props.okButtonProps || {},
        cancelText: props.cancelText || 'No',
        cancelButtonProps: props.cancelButtonProps || {},
        onOk: props.onOk,
        onCancel: props.onCancel,
        maskClosable: props.maskClosable,
        closable: props.closable,
    } );
};

export const openYesNoModal = ( {
    title,
    message,
    buttonType,
    onOk,
    onCancel,
    okButtonText = 'Yes',
    okButtonClassName,
    cancelButtonText = 'No',
    cancelButtonClassName,
    maskClosable = true,
    closable = true,
}: {
    title: ReactNode,
    message: ReactNode,
    buttonType: ORButtonType,
    onOk: () => void,
    onCancel?: () => void,
    okButtonText?: ReactNode,
    okButtonClassName?: string,
    cancelButtonText?: ReactNode,
    cancelButtonClassName?: string,
    maskClosable?: boolean,
    closable?: boolean,
} ) =>
{
    const modal = openModal( {
        type: 'confirm',
        title,
        centerTitle: true,
        children: <div className="flex flex-col place-items-center text-center w-full pb-4">
            <Typography.Paragraph className="mb-4">{ message }</Typography.Paragraph>
            <div className="flex place-items-center gap-4">
                <Button className={ `w-16 ${ cancelButtonClassName || '' }` } onClick={ () =>
                {
                    onCancel?.();
                    modal.destroy();
                } }>{ cancelButtonText }</Button>
                <Button className={ `w-16 ${ okButtonClassName || '' }` } type={ buttonType } onClick={ () =>
                {
                    onOk();
                    modal.destroy();
                } }>{ okButtonText }</Button>
            </div>
        </div>,
        maskClosable,
        closable,
    } );
    return modal;
};

const Container = tw.div`
  pb-4
`;