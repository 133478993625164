import * as Sentry from '@sentry/nextjs';
import { showReportDialog } from '@sentry/react';

import { FAKE_ERROR_MESSAGE } from '@/shared/errors';
import { getCookie } from '@/shared/tools';


export const processError = ( ...args: unknown[] ) =>
{
    if ( process.env.NODE_ENV !== 'production' )
    {
        if ( typeof window !== 'undefined' ) console.error( args );
        else console.log( args );
        return;
    }

    // production only
    const errorStr = JSON.stringify( args );

    // auth exceptions or those that have no contents are not an issue
    if ( errorStr.includes( 'NotAuthorizedException' ) ||
          errorStr.includes( '["auth Service",{}]' ) ||
          errorStr.includes( FAKE_ERROR_MESSAGE ) ||
          errorStr.includes( '528' ) ||
          errorStr.includes( '529' ) ||
          // this one is likely related to the backend not being updated to latest version yet
          errorStr.includes( '404' ) ||
          errorStr.includes( 'ERR_NETWORK' ) ||
          errorStr.includes( 'Network Error' ) ||
          errorStr.includes( 'Error fetching user data.' )
    ) return;

    // Sentry.captureException( errorStr );
    const eventId = Sentry.captureMessage( errorStr );

    showReportDialog( {
        eventId,
        user: {
            email: getCookie( { cName: 'orCurrentUserEmail' } ),
            name: getCookie( { cName: 'orCurrentUserName' } )
        },
        title: 'Error Report',
        subtitle: 'Please provide additional information about the error you encountered.',
        subtitle2: 'This information will help us fix the issue.',
        subtitle3: 'Thank you for your help!',
    } );
};